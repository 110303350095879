import awsConfig from '../cfn-output.json';

export const AWS_COGNITO_USER_POOL_ID = awsConfig.UserPoolId;
export const AWS_COGNITO_USER_POOL_CLIENT_ID = awsConfig.UserPoolClientId;
export const AWS_IDENTITY_POOL_ID = awsConfig.IdentityPoolId;
export const AWS_GRAPHQL_API_URL = awsConfig.GraphQLApiURL;
export const AWS_REGION = 'us-east-1';
export const AWS_COGNITO_USER_POOL_PROVIDER_URL = awsConfig.UserPoolProviderURL;
export const AWS_COGNITO_USER_POOL_ENDPOINT = awsConfig.UserPoolEndpoint;
export const AWS_S3_BUCKET_NAME = awsConfig.MediaServiceBucketName;
export const AWS_JOIN_WAITLIST_LAMBDA_URL = awsConfig.JoinWaitlistLambdaUrl;
export const OAUTH_CLIENT_ID = 'bjBzUncxeDkxcE04RzM3MUpYWHM6MTpjaQ';
export const API_KEY = awsConfig.ApiKey;
export const POST_PREVIEW_URL = awsConfig.PostImageLambdaUrl;
export const APP_URL = awsConfig.AppUrl;

export const WITH_WAITLIST = false;
