import { paths } from '@/routes-utils/paths.ts';
import { useNavigate } from '@remix-run/react';
import { Button } from '@synoptic/ui-kit/button/button.tsx';
import { Input } from '@synoptic/ui-kit/input/input.tsx';
import { clsx } from 'clsx';
import { FC, useRef } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { landingInput } from '../page.css.ts';
import { formContainer } from './form.css.ts';

type FormInputs = {
  email: string;
};

export const LoginEmailForm: FC<{
  className?: string;
}> = ({ className }) => {
  const { handleSubmit, formState, register } = useForm<FormInputs>();
  const formRef = useRef<HTMLFormElement>(null);
  const navigate = useNavigate();

  const submit: SubmitHandler<FormInputs> = async (data) => {
    navigate(paths.landingSignup, {
      state: { email: data.email },
      viewTransition: true,
    });
  };
  return (
    <form
      ref={formRef}
      className={clsx(formContainer, className)}
      onSubmit={handleSubmit(submit)}
    >
      <Input
        className={landingInput}
        type={'email'}
        placeholder={'Enter your email'}
        {...register('email', {
          required: true,
          pattern: {
            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
            message: 'Invalid email',
          },
        })}
        errorMessage={formState.errors.email?.message}
      />

      <Button size={'large'} type="submit">
        Continue
      </Button>
    </form>
  );
};
