import React from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import { clsx } from 'clsx';
import {
  carouselButton,
  carouselContent,
  carouselContentContainer,
  carouselContentVariants,
  carouselDot,
  carouselDots,
  carouselItem,
} from './carousel.css.ts';
import { IconButton } from '../button/icon-button.tsx';
import { ArrowLeftIcon } from '../icons/react/arrow-left.tsx';
import { ArrowRightIcon } from '../icons/react/arrow-right.tsx';
import { useCarousel } from './use-carousel.ts';
import { CarouselContext } from './carousel-context.tsx';
import { useCarouselTrigger } from './use-carousel-trigger.ts';
import { CarouselTrigger } from './carousel-trigger.tsx';
import Autoplay from 'embla-carousel-autoplay';
import { CarouselApi, CarouselProps } from './carousel-type.ts';
import { composeEventHandlers } from '../utils/compose-event-handlers.ts';

const Carousel = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & CarouselProps
>(function Carousel(
  {
    orientation = 'horizontal',
    opts,
    setApi,
    autoplay = false,
    loop = false,
    delay = 5000,
    className,
    children,
    ...props
  },
  ref,
) {
  const [carouselRef, api] = useEmblaCarousel(
    {
      ...opts,
      axis: orientation === 'horizontal' ? 'x' : 'y',
      loop,
    },
    [Autoplay({ delay, active: autoplay })],
  );

  const [canScrollPrev, setCanScrollPrev] = React.useState(false);
  const [canScrollNext, setCanScrollNext] = React.useState(false);

  const onSelect = React.useCallback((api: CarouselApi) => {
    if (!api) {
      return;
    }

    setCanScrollPrev(api.canScrollPrev());
    setCanScrollNext(api.canScrollNext());
  }, []);

  const scrollPrev = React.useCallback(() => {
    api?.scrollPrev();
  }, [api]);

  const scrollNext = React.useCallback(() => {
    api?.scrollNext();
  }, [api]);

  const handleKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'ArrowLeft') {
        event.preventDefault();
        scrollPrev();
      } else if (event.key === 'ArrowRight') {
        event.preventDefault();
        scrollNext();
      }
    },
    [scrollPrev, scrollNext],
  );

  React.useEffect(() => {
    if (!api || !setApi) {
      return;
    }

    setApi(api);
  }, [api, setApi]);

  React.useEffect(() => {
    if (!api) {
      return;
    }

    onSelect(api);
    api.on('reInit', onSelect);
    api.on('select', onSelect);

    return () => {
      api?.off('select', onSelect);
    };
  }, [api, onSelect]);

  return (
    <CarouselContext.Provider
      value={{
        carouselRef,
        api: api,
        opts,
        orientation:
          orientation || (opts?.axis === 'y' ? 'vertical' : 'horizontal'),
        scrollPrev,
        scrollNext,
        canScrollPrev,
        canScrollNext,
      }}
    >
      <div
        ref={ref}
        onKeyDownCapture={handleKeyDown}
        className={className}
        role="region"
        aria-roledescription="carousel"
        {...props}
      >
        {children}
      </div>
    </CarouselContext.Provider>
  );
});

const CarouselContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & { containerClassName?: string }
>(function CarouselContent({ className, containerClassName, ...props }, ref) {
  const { carouselRef, orientation } = useCarousel();

  return (
    <div
      ref={carouselRef}
      className={clsx(carouselContentContainer, containerClassName)}
    >
      <div
        ref={ref}
        className={clsx(
          carouselContent,
          orientation && carouselContentVariants[orientation],
          className,
        )}
        {...props}
      />
    </div>
  );
});

const CarouselItem = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(function CarouselItem({ className, ...props }, ref) {
  return (
    <div
      ref={ref}
      role="group"
      aria-roledescription="slide"
      className={clsx(carouselItem, className)}
      {...props}
    />
  );
});

const CarouselPrevious = React.forwardRef<
  HTMLButtonElement,
  React.ComponentProps<typeof IconButton>
>(function CarouselPrevious(
  { className, variant = 'secondary', size = 'small', onClick, ...props },
  ref,
) {
  const { scrollPrev, canScrollPrev } = useCarousel();

  return (
    <IconButton
      ref={ref}
      variant={variant}
      size={size}
      className={clsx(carouselButton, className)}
      disabled={!canScrollPrev}
      onClick={composeEventHandlers(onClick, scrollPrev)}
      {...props}
    >
      <ArrowLeftIcon />
    </IconButton>
  );
});

const CarouselNext = React.forwardRef<
  HTMLButtonElement,
  React.ComponentProps<typeof IconButton>
>(function CarouselNext(
  { className, variant = 'secondary', size = 'small', onClick, ...props },
  ref,
) {
  const { scrollNext, canScrollNext } = useCarousel();

  return (
    <IconButton
      ref={ref}
      variant={variant}
      size={size}
      className={clsx(carouselButton, className)}
      disabled={!canScrollNext}
      onClick={composeEventHandlers(onClick, scrollNext)}
      {...props}
    >
      <ArrowRightIcon />
    </IconButton>
  );
});

const CarouselDots = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithRef<'div'>
>(function CarouselDots({ className }, ref) {
  const { api } = useCarousel();

  const { scrollSnaps } = useCarouselTrigger(api);

  return (
    <div ref={ref} className={carouselDots}>
      {scrollSnaps.map((_, index) => (
        <CarouselTrigger
          key={index}
          index={index}
          className={clsx(carouselDot, className)}
        />
      ))}
    </div>
  );
});

export {
  type CarouselApi,
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselPrevious,
  CarouselNext,
  CarouselDots,
};
