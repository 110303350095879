import {
  FieldFunctionOptions,
  FieldMergeFunction,
  InMemoryCache,
} from '@apollo/client';
import { QuerySearchArgs, QueryTypeaheadArgs } from '../__generated__/graphql';

export type Page = {
  __typename?: string;
  items: Array<unknown>;
  nextToken?: string;
};

const mergePaginatedCollections: FieldMergeFunction<
  Page,
  Page,
  FieldFunctionOptions<{ limit?: number; nextToken?: string }>
> = (existingPage: Page = { items: [] }, incomingPage: Page, { args }) => {
  // requesting with nextToken === null most probably means that we want to do fresh refetch
  if (args?.nextToken === null) {
    return incomingPage;
  }

  // apollo can write multiple times the same data. (e.g. if we navigate to page before prefetching is finished)
  // theoretically that's enough to just check nextToken. In case of problems migrate to filtering incoming items from duplicates
  // https://github.com/apollographql/apollo-client/issues/10711
  if (incomingPage.nextToken === existingPage.nextToken) {
    return existingPage;
  }

  return {
    ...incomingPage,
    items: [...existingPage.items, ...incomingPage.items],
  };
};

export const makeApolloCache = () =>
  new InMemoryCache({
    typePolicies: {
      User: {
        fields: {
          posts: {
            keyArgs: false,
            merge: mergePaginatedCollections,
          },
          protectedFields: {
            merge: true,
          },
        },
      },
      RegularPost: {
        fields: {
          media: {
            merge: false,
          },
        },
      },
      S3Object: {
        keyFields: ['key'],
      },
      Tournament: {
        fields: {
          submissions: {
            keyArgs: false,
            merge: mergePaginatedCollections,
          },
          participants: {
            keyArgs: false,
            merge: mergePaginatedCollections,
          },
          medals: {
            merge: false,
          },
        },
      },
      Stream: {
        fields: {
          paidStreamData: {
            merge: true,
          },
        },
      },

      UserSettings: {
        keyFields: [],
      },

      Query: {
        fields: {
          feed: {
            keyArgs: ['scope'],
            merge: mergePaginatedCollections,
          },
          viralPosts: {
            keyArgs: false,
            merge: mergePaginatedCollections,
          },
          leaderboard: {
            keyArgs: ['type'],
          },
          tournaments: {
            keyArgs: ['state', 'category'],
            merge: mergePaginatedCollections,
          },
          closedTournaments: {
            keyArgs: ['category'],
            merge: mergePaginatedCollections,
          },
          tournament: {
            read(_, { args, toReference }) {
              return toReference({
                __typename: 'Tournament',
                id: args?.id,
              });
            },
          },
          posts: {
            keyArgs: false,
            merge: mergePaginatedCollections,
          },
          post: {
            read(_, { canRead, toReference, args }) {
              const postTypes = [
                'RegularPost',
                'RePost',
                'StreamPost',
                'TournamentPost',
              ];

              for (const typename of postTypes) {
                const reference = toReference({
                  __typename: typename,
                  id: args?.id,
                });
                if (canRead(reference)) {
                  return reference;
                }
              }
              return undefined;
            },
          },
          comments: {
            // TODO: don't forget to handle ordering
            keyArgs: ['parentPostId', 'voteType'],
            merge: mergePaginatedCollections,
          },
          streams: {
            keyArgs: ['queryType', 'streamCategory'],
            merge: mergePaginatedCollections,
          },
          streamPosts: {
            keyArgs: ['id'],
            merge: mergePaginatedCollections,
          },
          userStreams: {
            keyArgs: ['username'],
            merge: mergePaginatedCollections,
          },
          typeahead: {
            keyArgs: ['input', ['type', 'text']],
            merge(existing, incoming, { args }) {
              const merged = existing ? existing.streams.slice(0) : [];

              if (incoming?.streams) {
                const {
                  input: { offset },
                } = args as QueryTypeaheadArgs;

                for (let i = 0; i < incoming.streams.length; ++i) {
                  merged[(offset || 0) + i] = incoming.streams[i];
                }
              }

              return {
                ...incoming,
                streams: merged,
              };
            },
          },
          customCollectionPosts: {
            keyArgs: ['collectionId'],
            merge: mergePaginatedCollections,
          },
          search: {
            keyArgs: [
              'input',
              [
                'type',
                'text',
                'filters',
                ['type', 'tournament', ['category', 'state']],
                ['filters', ['stream', ['streamCategory']]],
              ],
            ],
            merge(existing, incoming, { args }) {
              const merged = existing ? existing.items.slice(0) : [];

              if (incoming?.items) {
                const {
                  input: { offset = 0 },
                } = args as QuerySearchArgs;

                for (let i = 0; i < incoming.items.length; ++i) {
                  merged[offset + i] = incoming.items[i];
                }
              }

              return {
                ...incoming,
                offset: existing?.offset === null ? null : incoming?.offset,
                items: merged,
              };
            },
          },
          notifications: {
            keyArgs: ['read'],
            merge: mergePaginatedCollections,
          },
          followersByName: {
            keyArgs: ['isInfluential', 'username', 'limit'],
            merge: mergePaginatedCollections,
          },
          followingByName: {
            merge: mergePaginatedCollections,
          },
        },
      },

      Subscription: {
        fields: {
          feed: {
            keyArgs: ['feedScope'],
          },
        },
      },
    },
  });
