import { paths } from '@/routes-utils/paths.ts';
import { sanitizeUrl } from '@braintree/sanitize-url';

export const sanitizeRedirectParam = (
  redirectParam: string | null | undefined,
  defaultRedirectPath?: string,
) => {
  if (!redirectParam) return defaultRedirectPath || paths.default;

  try {
    const url = new URL(redirectParam, window.location.origin);

    if (url.origin === window.location.origin) {
      const redirectTo = url.pathname + url.search + url.hash;
      return sanitizeUrl(redirectTo);
    }

    return defaultRedirectPath || paths.default;
  } catch {
    return defaultRedirectPath || paths.default;
  }
};
