import { clsx } from 'clsx';
import { content, page, pageWrapper } from './page.css';

export const Page: React.FC<
  React.PropsWithChildren<{ className?: string }>
> = ({ children, className }) => {
  return (
    <div className={clsx(pageWrapper, className)}>
      <div className={page}>{children}</div>
    </div>
  );
};

export const PageContent: React.FC<
  React.PropsWithChildren<{ className?: string }>
> = ({ children, className }) => {
  return <div className={clsx(content, className)}>{children}</div>;
};
